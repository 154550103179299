<script setup lang="ts">
import { Icon } from "@toruslabs/vue-components/Icon";
import { Toast } from "@toruslabs/vue-components/Toast";
import { useI18n } from "petite-vue-i18n";
import { computed, ref, watch } from "vue";

import useToast from "@/composables/useToast";
import { TOAST_TYPES } from "@/utils/enums";

const { toastMessage, setToastMessage } = useToast();

type TORUS_UI_TOAST_TYPE = "default" | "success" | "danger" | "info";

const { t } = useI18n();

withDefaults(
  defineProps<{
    hasNavbar?: boolean;
  }>(),
  {
    hasNavbar: false,
  }
);

const timeoutTimer = ref(0);

const toastIcon = computed(() => {
  if (toastMessage.value.type === TOAST_TYPES.SUCCESS) return "check-circle-solid-icon";
  if (toastMessage.value.type === TOAST_TYPES.ERROR) return "x-circle-solid-icon";
  return "information-circle-solid-icon";
});

const deleteToastMessage = () => {
  setToastMessage({ message: "" });
  clearTimeout(timeoutTimer.value);
};

watch(toastMessage, (val) => {
  if (val.message) {
    timeoutTimer.value = window.setTimeout(() => {
      deleteToastMessage();
    }, 7000);
  }
});
</script>

<template>
  <Toast
    v-if="toastMessage?.message"
    :open="true"
    :variant="toastMessage.type as TORUS_UI_TOAST_TYPE"
    placement="bottom-center"
    class="!w-[90%] mx-auto smb:!w-[375px]"
  >
    <div class="flex items-center justify-between w-full">
      <Icon :name="toastIcon" class="mr-2 w-5" />
      <p class="text-sm font-normal flex flex-1">{{ t(toastMessage.message) }}</p>
      <Icon name="x-solid-icon" class="mt-[3px] cursor-pointer w-5" @click="deleteToastMessage" />
    </div>
  </Toast>
</template>
