import { addHexPrefix, bigIntToHex, ecsign, fromSigned, hashPersonalMessage, stripHexPrefix, toUnsigned } from "@ethereumjs/util";

import { SUPPORTED_KEY_CURVES, SUPPORTED_KEY_CURVES_TYPE } from "./interfaces";
import { getBufferFromHexKey } from "./utils";

export const hashMessage = (message: string, enc: BufferEncoding = "utf8"): Buffer => {
  const bufferedMessage = Buffer.from(message, enc);
  const el = hashPersonalMessage(bufferedMessage);
  return Buffer.from(el);
};

export function concatSig(v: bigint, r: Uint8Array, s: Uint8Array): string {
  const rSig = fromSigned(r);
  const sSig = fromSigned(s);
  const vSig = v;
  const rStr = Buffer.from(toUnsigned(rSig)).toString("hex").padStart(64, "0");
  const sStr = Buffer.from(toUnsigned(sSig)).toString("hex").padStart(64, "0");
  const vStr = stripHexPrefix(bigIntToHex(vSig));
  return addHexPrefix(rStr.concat(sStr, vStr));
}

export const signMessage = async (
  privateKey: string,
  data: string,
  type: SUPPORTED_KEY_CURVES_TYPE = SUPPORTED_KEY_CURVES.SECP256K1
): Promise<string> => {
  if (type === SUPPORTED_KEY_CURVES.SECP256K1) {
    const privKey = getBufferFromHexKey(privateKey);
    const message = stripHexPrefix(data);
    const msgSig = ecsign(Buffer.from(message, "hex"), privKey);
    const rawMsgSig = concatSig(msgSig.v, msgSig.r, msgSig.s);
    return rawMsgSig;
  }
  if (type === SUPPORTED_KEY_CURVES.ED25519) {
    const { sign } = await import("@toruslabs/tweetnacl-js");
    return Buffer.from(sign.detached(Buffer.from(data, "hex"), getBufferFromHexKey(privateKey))).toString("hex");
  }
  throw new Error(`Unsupported curve type: ${type}`);
};
