<script setup lang="ts">
import { Loader } from "@toruslabs/vue-components/Loader";
import { useI18n } from "petite-vue-i18n";
import { computed } from "vue";

import { dappModule } from "@/store/index";
import { DEFAULT_LOGO_DARK, DEFAULT_LOGO_LIGHT } from "@/utils/enums";

const { t } = useI18n();

withDefaults(
  defineProps<{
    showPageReloadWarning?: boolean;
    showCustomVerifier?: boolean;
  }>(),
  {
    showPageReloadWarning: false,
    showCustomVerifier: true,
  }
);

const currentDappModule = computed(() => {
  return dappModule;
});

const isDark = computed(() => {
  return currentDappModule.value?.isDarkMode || false;
});

const useLogoLoader = computed(() => {
  return currentDappModule.value?.useLogoLoader || false;
});

const whiteLabel = computed(() => {
  return currentDappModule.value?.whiteLabel || undefined;
});

const hasWhiteLabel = computed(() => {
  return whiteLabel.value && Object.keys(whiteLabel.value).length > 0;
});

const whiteLabelLogo = computed(() => {
  return {
    light: hasWhiteLabel.value ? whiteLabel.value?.logoLight : "",
    dark: hasWhiteLabel.value ? whiteLabel.value?.logoDark : "",
  };
});

const isCustomVerifier = computed(() => {
  return currentDappModule.value?.isCustomVerifier || false;
});

const siteUrl = computed(() => {
  return currentDappModule.value?.siteMetadata.url || "";
});

const siteName = computed(() => {
  return currentDappModule.value?.siteMetadata.name || "";
});

const hasOwnLogo = computed(() => {
  const dappLogo = isDark.value ? whiteLabelLogo.value?.dark : whiteLabelLogo.value?.light;
  return ![DEFAULT_LOGO_DARK, DEFAULT_LOGO_LIGHT].includes(dappLogo) && dappLogo !== "";
});

const showLogo = computed(() => {
  return hasOwnLogo.value && useLogoLoader.value;
});

const dappName = computed(() => {
  return isCustomVerifier.value ? whiteLabel.value?.appName || siteName : "Web3Auth";
});

const dappUrl = computed(() => {
  return isCustomVerifier.value ? whiteLabel.value?.appUrl || siteUrl : "https://auth.web3auth.io";
});
</script>

<template>
  <div class="text-center redirect-loader">
    <Loader
      aria-live="polite"
      aria-busy="true"
      :logo-dark="whiteLabelLogo.dark"
      :logo-light="whiteLabelLogo.light"
      :show-logo="showLogo"
      class="mb-4"
    />
    <div v-if="isCustomVerifier && showCustomVerifier" class="text-base font-normal text-app-gray-500 dark:text-app-gray-400">
      {{ t("login.constructYourKey") }}
      <span class="underline text-app-primary-500">
        {{ dappName || dappUrl }}
      </span>
    </div>
    <div v-if="showPageReloadWarning" class="text-base font-normal text-app-gray-500 dark:text-app-gray-400">
      <span>{{ t("login.pageReloadWarning") }}</span>
    </div>
    <slot name="contents"></slot>
  </div>
</template>
