import { ref } from "vue";

import { ToastMessage } from "@/utils/interfaces";

const defaultToastMessage: ToastMessage = {
  message: "",
};

const toastMessage = ref<ToastMessage>(defaultToastMessage);

const useToast = () => {
  const setToastMessage = (details: ToastMessage) => {
    toastMessage.value = details;
  };

  return {
    toastMessage,
    setToastMessage,
  };
};

export default useToast;
