/* eslint-disable promise/no-nesting */
// This optional code is used to register a service worker.
// register() is not called by default.

import bowser from "bowser";
import log from "loglevel";

import browserInfo from "./browserConfig";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl, { updateViaCache: "imports", scope: import.meta.env.BASE_URL || "/" })
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              log.info("New content is available and will be used when all tabs for this page are closed.");

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              log.info("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
      return undefined;
    })
    .catch((error) => {
      log.warn("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    cache: "no-cache",
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
        // No service worker found. Probably a different app. Reload the page.

        return navigator.serviceWorker.ready
          .then((registration) => {
            return registration.unregister();
          })
          .catch(() => log.warn("Error unregistering service worker."))
          .then(() => {
            window.location.reload();
            return undefined;
          })
          .catch(() => log.warn("Error unregistering service worker."));
      }
      // Service worker found. Proceed as normal.
      return registerValidSW(swUrl, config);
    })
    .catch(() => {
      log.info("No internet connection found. App is running in offline mode.");
    });
}

export function register(config?: Config): void {
  if (import.meta.env.MODE === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(import.meta.env.BASE_URL || "/", window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if BASE_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${import.meta.env.BASE_URL || "/"}service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready
          .then(() => {
            log.info("This web app is being served cache-first by a service worker");
            return undefined;
          })
          .catch((err) => {
            log.warn("Error during service worker registration:", err);
          });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

export function unregister(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        return registration.unregister();
      })
      .catch((error: Error) => {
        log.warn(error.message);
      });
  }
}

// Firefox and iOS Chrome
const canExcludeSw =
  browserInfo.browser.name === bowser.BROWSER_MAP.firefox ||
  (browserInfo.os.name === bowser.OS_MAP.iOS && browserInfo.browser.name === bowser.BROWSER_MAP.chrome);

if (canExcludeSw && "serviceWorker" in navigator) {
  // remove service worker for firefox
  navigator.serviceWorker
    .getRegistrations()
    .then((x) => x.map((y) => y.unregister()))
    .catch((error) => log.error(error));
} else {
  register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event: Event) => {
          if ((event?.target as { state?: string })?.state === "activated") {
            // window.location.reload();
            // don't reload cause it re-sends the email
          }
        });
        log.info("skipping waiting on register");
      }
    },
  });
}
