import { AGGREGATE_VERIFIER, AggregateLoginParams, SubVerifierDetails } from "@toruslabs/customauth";
import { storageAvailable } from "@web3auth/auth";

import useConfig from "@/composables/useConfig";

import { CustomAuthStateParams, LoginConfigItem, PasskeysCustomAuthStateParams } from "./interfaces";

const { config } = useConfig();

export function generateCustomAuthParams(
  localConfig: LoginConfigItem,
  customState: Partial<CustomAuthStateParams> = {}
): AggregateLoginParams | SubVerifierDetails {
  const state = {
    ...customState,
    version: config.value?.version as string,
  };
  if (localConfig.jwtParameters.isVerifierIdCaseSensitive === false && localConfig.jwtParameters.login_hint) {
    localConfig.jwtParameters.login_hint = localConfig.jwtParameters.login_hint.toLowerCase();
  }
  if (localConfig.verifierSubIdentifier) {
    return {
      aggregateVerifierType: AGGREGATE_VERIFIER.SINGLE_VERIFIER_ID,
      verifierIdentifier: localConfig.verifier,
      subVerifierDetailsArray: [
        {
          typeOfLogin: localConfig.typeOfLogin,
          verifier: localConfig.verifierSubIdentifier,
          clientId: localConfig.clientId,
          jwtParams: localConfig.jwtParameters,
          customState: state,
        },
      ],
    };
  }
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState: state,
  };
}

export function generatePasskeysCustomAuthParams(
  localConfig: LoginConfigItem,
  customState: Partial<PasskeysCustomAuthStateParams> = {}
): AggregateLoginParams | SubVerifierDetails {
  const state = {
    ...customState,
    version: config.value?.version as string,
  };
  if (localConfig.verifierSubIdentifier) {
    return {
      aggregateVerifierType: AGGREGATE_VERIFIER.SINGLE_VERIFIER_ID,
      verifierIdentifier: localConfig.verifier,
      subVerifierDetailsArray: [
        {
          typeOfLogin: localConfig.typeOfLogin,
          verifier: localConfig.verifierSubIdentifier,
          clientId: localConfig.clientId,
          jwtParams: localConfig.jwtParameters,
          customState: state,
        },
      ],
    };
  }
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState: state,
  };
}

export function clearLoginDetailsStorage(scope: string): void {
  if (storageAvailable("localStorage")) window.localStorage.removeItem(`torus_login_${scope}`);
  // No need to clear server details cause they auto expire and scope is never re-used for different login attempts
}
