import { TORUS_NETWORK_TYPE } from "@toruslabs/constants";
import { post } from "@toruslabs/http-helpers";
import log from "loglevel";

import useConfig from "@/composables/useConfig";

const { config } = useConfig();

// We are using this mostly for register mfa and tkey input screens.
// This helps to authenticate the user and get the public address for the user.
export const getAuthToken = async (params: { public_address: string; signatures: string[]; network: TORUS_NETWORK_TYPE }) => {
  try {
    const { token } = await post<{ token: string }>(`${config.value.apiHost}/api/v1/authenticate`, params);
    return token;
  } catch (error: unknown) {
    log.error("Failed to fetch auth token", error);
    return null;
  }
};
