import { post } from "@toruslabs/http-helpers";
import log from "loglevel";

import useConfig from "@/composables/useConfig";
import { ExternalAuthTokenPayload, SaveLoginSessionMetadata } from "@/utils/interfaces";

const { config } = useConfig();

export const saveLoginSessionMetadata = async (params: SaveLoginSessionMetadata): Promise<void> => {
  try {
    await post<string>(`${config.value.apiHost}/api/v1/save_login_metadata`, params);
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to save login session metadata", error);
  }
};

export const getExternalAuthToken = async (params: ExternalAuthTokenPayload): Promise<string> => {
  try {
    const { token } = await post<{ token: string }>(`${config.value.apiHost}/api/v2/external_token`, params);
    return token;
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to get external auth token", error);
    return null;
  }
};
