<script setup lang="ts">
/*
Steps:
We check for loginId, if not present throw an error.
*/
import { AUTH_ACTIONS, type AUTH_ACTIONS_TYPE, base64toJSON, type BaseLoginParams } from "@web3auth/auth";
import log from "loglevel";
import { useI18n } from "petite-vue-i18n";
import { defineAsyncComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

import { StartPageError } from "@/errors/startError";
import { rehydrateLoginConfig } from "@/sdk/rehydrateLoginConfig";
// import { doneAndRedirect } from "@/handlers/authUtils";
import { dappModule } from "@/store/modules/dapp";
import loginPerfModule from "@/store/modules/loginPerf";
import userModule from "@/store/modules/user";
import { LOGIN_FLOW, SOCIAL_LOGIN_ACTION, SOCIAL_LOGIN_SOURCE } from "@/utils/enums";

import RedirectLoader from "../../containers/RedirectLoader";

const ErrorBlock = defineAsyncComponent(() => import("../../components/ErrorBlock"));
const MFaCard = defineAsyncComponent(() => import("./MfaCard.vue"));
const { t } = useI18n();

const errorType = ref("");
const showReloginPrompt = ref(false);
const continueLoader = ref(false);
const route = useRoute();
let promise: Promise<typeof import("@/sdk/initiateUserLogin")>;

const clearLoginDetailsStorage = () => {
  userModule.logout();
  if (window?.sessionStorage) {
    window.sessionStorage.removeItem("tKeyModule");
    window.sessionStorage.removeItem("loginPerfModule");
  }
};

const initiateLogin = async () => {
  // just in case if there is no promise.
  if (!promise) promise = import("@/sdk/initiateUserLogin");
  const { initiateUserLogin } = await promise;
  if (dappModule.actionType === AUTH_ACTIONS.ADD_SOCIAL_FACTOR || dappModule.actionType === AUTH_ACTIONS.MODIFY_SOCIAL_FACTOR) {
    const socialFactor = `${SOCIAL_LOGIN_SOURCE.DAPP}:${dappModule.actionType === AUTH_ACTIONS.ADD_SOCIAL_FACTOR ? SOCIAL_LOGIN_ACTION.ADD_FACTOR : SOCIAL_LOGIN_ACTION.CHANGE_FACTOR}`;
    await initiateUserLogin(dappModule.currentLoginProvider, {
      extraLoginOptions: dappModule.extraLoginOptions,
      socialFactorFlow: socialFactor,
    });
  } else if (dappModule.actionType === AUTH_ACTIONS.ADD_AUTHENTICATOR_FACTOR) {
    await initiateUserLogin(dappModule.currentLoginProvider, {
      loginFlow: LOGIN_FLOW.REGISTER,
      authToken: userModule.authToken,
      issuer: dappModule.issuerName,
      oAuthVerifier: userModule.userInfo?.aggregateVerifier || userModule.userInfo?.verifier,
      oAuthVerifierId: userModule.userInfo?.verifierId,
    });
  } else if (dappModule.actionType === AUTH_ACTIONS.ADD_PASSKEY_FACTOR) {
    await initiateUserLogin(dappModule.currentLoginProvider, {
      loginFlow: LOGIN_FLOW.REGISTER,
    });
  } else {
    await initiateUserLogin(dappModule.currentLoginProvider, { extraLoginOptions: dappModule.extraLoginOptions });
  }
};

onBeforeMount(async () => {
  try {
    const { hash } = route;
    const hashUrl = new URL(`${window.location.origin}?${hash.slice(1)}`);
    const b64Params = hashUrl.searchParams.get("b64Params");
    // TODO: catch and rethrow errors with error codes
    // TODO: show where user needs to copy device share from

    // check if this key is present.
    // update all the config (which is not going to be changed) in the dapp module.
    if (b64Params) {
      let baseParams = {} as BaseLoginParams;
      try {
        baseParams = { ...baseParams, ...base64toJSON<BaseLoginParams>(b64Params) };
      } catch (error) {
        log.error("invalid base64", error);
        throw StartPageError.invalidParams(`hash param: ${b64Params} is not a valid base64 string`);
      }
      // stop storing dapp modules
      // store loginId in user module
      // rehydrate all modules in app.vue from loginId if available in userModule
      // in start.vue, get loginId and store it to user module
      // then call rehydrate function
      const { loginId, sessionNamespace, storageServerUrl } = baseParams;
      if (!loginId || typeof loginId !== "string") {
        throw StartPageError.invalidParams("Login identifier missing. Please start the login again.");
      }
      clearLoginDetailsStorage();
      userModule.setLoginConfig({ loginId, sessionNamespace, storageServerUrl });
      promise = import("@/sdk/initiateUserLogin");
      // initiate the base transaction here.
      await Promise.all([
        loginPerfModule.markRouteAndTime({
          route: "start",
          transactionName: "start",
        }),
        rehydrateLoginConfig(),
      ]);
    }

    const redirectActions: AUTH_ACTIONS_TYPE[] = [
      AUTH_ACTIONS.LOGIN,
      AUTH_ACTIONS.ADD_SOCIAL_FACTOR,
      AUTH_ACTIONS.MODIFY_SOCIAL_FACTOR,
      AUTH_ACTIONS.ADD_AUTHENTICATOR_FACTOR,
      AUTH_ACTIONS.ADD_PASSKEY_FACTOR,
    ];

    if (redirectActions.includes(dappModule.actionType)) {
      await initiateLogin();
    } else if (dappModule.actionType === AUTH_ACTIONS.ENABLE_MFA || dappModule.actionType === AUTH_ACTIONS.MANAGE_MFA) {
      showReloginPrompt.value = true;
    } else {
      throw StartPageError.noActionHandler();
    }
  } catch (error: unknown) {
    log.error(error);
    errorType.value = (error as Error).message;
  }
});

const onClose = () => {
  log.info("Cancel login");
};

const startLoginForEnableMFA = async () => {
  try {
    continueLoader.value = true;
    await initiateLogin();
  } catch (error: unknown) {
    log.error(error);
    errorType.value = (error as Error).message;
  }
};
</script>

<template>
  <main class="flex flex-col items-center justify-center h-screen">
    <div v-if="dappModule.whiteLabelLoaded || errorType" class="flex items-center flex-grow">
      <Suspense v-if="errorType">
        <ErrorBlock :error-type="errorType" @on-close="onClose" />
        <template #fallback>
          <RedirectLoader :simple-loader="true"> </RedirectLoader>
        </template>
      </Suspense>

      <Suspense v-else-if="showReloginPrompt">
        <MFaCard :continue-loader="continueLoader" @startLoginForEnableMFA="startLoginForEnableMFA" />
        <template #fallback>
          <RedirectLoader :simple-loader="true"> </RedirectLoader>
        </template>
      </Suspense>

      <RedirectLoader v-else :simple-loader="true">
        <template #contents>
          <p class="text-center text-lg text-app-gray-500 dark:text-app-gray-400 px-6">{{ t("common.loader-not-done-note") }}</p>
        </template>
      </RedirectLoader>
    </div>
  </main>
</template>

<style scoped>
.setup_sheild {
  width: auto;

  @media only screen and (max-width: 375px) {
    width: 12rem;
  }

  @media only screen and (min-width: 400px) {
    width: 13rem;
  }

  @media only screen and (min-width: 1024px) {
    width: 14rem;
  }
}
</style>
