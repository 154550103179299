import { ErrorCodes } from "@/utils/interfaces";

import { AuthServiceError } from "./abstract";

export class StartPageError extends AuthServiceError {
  protected static messages: ErrorCodes = {
    1000: "Custom",
    1001: "Invalid login session. Please restart the login process again.",
    1002: "Invalid params",
    1003: "No action handler found, please restart the login process.",
    1004: "There was an error rehydrating the login session, Please try again or go back to the dapp.",
  };

  public constructor(code: number, message?: string) {
    // takes care of stack and proto
    super(code, message);

    // Set name explicitly as minification can mangle class names
    Object.defineProperty(this, "name", { value: "StartPageError" });
  }

  public static fromCode(code: number, extraMessage = ""): AuthServiceError {
    return new StartPageError(code, `${StartPageError.messages[code]}, ${extraMessage}`);
  }

  public static invalidLoginSession(extraMessage = ""): AuthServiceError {
    return StartPageError.fromCode(1001, extraMessage);
  }

  public static invalidParams(extraMessage = ""): AuthServiceError {
    return StartPageError.fromCode(1002, extraMessage);
  }

  public static noActionHandler(extraMessage = ""): AuthServiceError {
    return StartPageError.fromCode(1003, extraMessage);
  }

  public static rehydrateError(extraMessage = ""): AuthServiceError {
    return StartPageError.fromCode(1004, extraMessage);
  }
}
