export class SkipVuexPersist {
  // eslint-disable-next-line no-use-before-define
  private static instance: SkipVuexPersist;

  public skippedProps: Record<string, Record<string, boolean>> = {};

  static getInstance(): SkipVuexPersist {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new SkipVuexPersist();
    return this.instance;
  }

  addSkippedProps(module: string, key: string) {
    if (this.skippedProps[module]) {
      this.skippedProps[module] = { ...this.skippedProps[module], [key]: true };
    } else {
      this.skippedProps[module] = { [key]: true };
    }
  }
}
export function SkipSync(module: string) {
  return (_: unknown, key: string) => {
    const skipVuexPersist = SkipVuexPersist.getInstance();
    skipVuexPersist.addSkippedProps(module, key);
  };
}
