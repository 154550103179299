import "./globals";
import "./setLogLevel";
import "./registerServiceWorker";
import "./main.css";

import { createGtm } from "@gtm-support/vue-gtm";
import { BUILD_ENV } from "@web3auth/auth";
import { createApp } from "vue";

import { getUserLanguage } from "@/utils/utils";

import App from "./App.vue";
import useConfig from "./composables/useConfig";
import displayPlugin from "./plugins/displayPlugin";
import i18n, { loadLanguageAsync } from "./plugins/i18n-setup";
import createIcons from "./plugins/iconsPlugin";
import router from "./router";
import store from "./vuexStore";

const { environment } = useConfig();
const app = createApp(App as unknown);

app.use(i18n).use(router).use(store).use(displayPlugin).use(createIcons);

app.mount("#app");

loadLanguageAsync(getUserLanguage());

if (environment === BUILD_ENV.PRODUCTION)
  app.use(
    createGtm({
      id: "GTM-5RRFZGD8", // GTM tag for auth.web3auth.io
      defer: true,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
    })
  );
