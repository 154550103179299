export const ERROR_TYPES = {
  LOCAL_STORAGE_NOT_DETECTED: "LOCAL_STORAGE_NOT_DETECTED",
  SESSION_STORAGE_NOT_DETECTED: "SESSION_STORAGE_NOT_DETECTED",
  TPC_NOT_SUPPORTED: "TPC_NOT_SUPPORTED",
  NETWORK_RESPONSE_FAILED: "NETWORK_RESPONSE_FAILED",
  DUPLICATE_TOKEN_FOUND: "DUPLICATE_TOKEN_FOUND",
  BUSY_NETWORK: "BUSY_NETWORK",
  KEY_ASSIGN_FAILED: "KEY_ASSIGN_FAILED",
  VERIFIER_NOT_SUPPORTED: "VERIFIER_NOT_SUPPORTED",
  INVALID_PARAMS: "INVALID_PARAMS",
  NO_MFA_FACTOR_ENABLED: "NO_MFA_FACTOR_ENABLED",
  MFA_FACTOR_MANDATORY: "MFA_FACTOR_MANDATORY",
  PAGE_RELOADED: "PAGE_RELOADED",
  DEFAULT: "DEFAULT",
};
export const ERROR_MESSAGES = {
  PAGE_RELOADED: `This page has been reloaded and should not be. Please start the login process again.`,
  LOCAL_STORAGE_NOT_DETECTED: `Unable to detect browser local storage.
    This may be due to browser security settings.
    Adjust your current browser settings from 'Strict' to 'Moderate'.`,
  SESSION_STORAGE_NOT_DETECTED: `Unable to detect browser session storage.
    This may be due to browser security settings.
    Adjust your current browser settings from 'Strict' to 'Moderate'.`,
  TPC_NOT_SUPPORTED: `Unable to detect device share.
    This may be due to browser security settings.
    Adjust your current browser settings from 'Strict' to 'Moderate'.
    Alternatively, login via a Chrome Browser.`,
  NETWORK_RESPONSE_FAILED: `Unable to detect login share from the Auth Network.
    This may be due to slow internet connection. 
    Check your internet speed and try again. If you're using a vpn, please turn it off.`,
  DUPLICATE_TOKEN_FOUND: `Unable to verify.
    This may be due to invalid login. 
    Kindly log out of your social login provider on your current browser and login again.`,
  BUSY_NETWORK: `Unable to connect to Auth Network.
    The Network may be congested.
    Please try again in 5 minutes.`,
  KEY_ASSIGN_FAILED: `A key has not been assigned to you.
    This might be due to communication with the Auth Network Nodes.
    Kindly relogin to try again. If problem persists, please Contact Support.`,
  VERIFIER_NOT_SUPPORTED: `Verifier not supported.
    Kindly ensure you have a live verifier on the right network (Testnet/Mainnet) .
    Set up / Check verifier status here: dashboard.web3auth.io `,
  INVALID_PARAMS: `Missing parameter: state. 
    This maybe due to invalid login flow.`,
  NO_MFA_FACTOR_ENABLED: `Invalid MFA Settings. 
  Please enable atleast one mfa factor.`,
  MFA_FACTOR_MANDATORY: `Invalid MFA Settings. 
  Please ensure at least one MFA factor is mandatory other than the device or passkeys.`,
  DEFAULT: `There seems to be some bug in the code.
    Please contact support to fix this.
  `,
  CALLBACK_INVALID_SOCIAL_ACTION: "Invalid social action source selected",
  CALLBACK_WEBAUTH_REGISTER_FLOW_PUBKEY: "register only flow must return an extended pubkey",
  SOMETHING_WENT_WRONG: "Something went wrong",
  USER_LOCATION_BLOCKED: `Access blocked due to Google Cloud restrictions in your region or network. Please use a VPN or another service to continue. If you are already using a VPN, try disabling it.`,
};
