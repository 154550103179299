import { post } from "@toruslabs/http-helpers";
import log from "loglevel";

import useConfig from "@/composables/useConfig";
import { IDBUser } from "@/utils/interfaces";

const { config } = useConfig();

export const fetchLoginCount = async (params: { public_address: string; network: string; signatures: string[] }): Promise<number> => {
  try {
    const { login_count } = await post<{ login_count: number }>(`${config.value.apiHost}/api/v1/user/login_count`, params);
    return login_count;
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to fetch login count", error);
    return 0;
  }
};

export const getUser = async (params: { public_address: string; network: string; signatures: string[] }): Promise<IDBUser | null> => {
  try {
    const data = await post<{ user: IDBUser }>(`${config.value.apiHost}/api/v1/user`, params);
    return data ? data.user : null;
  } catch (error) {
    log.error("Failed to fetch user", error);
    return null;
  }
};
