import type { IconSets } from "@toruslabs/vue-components";

export default {
  AuthenticatorIcon: () => import("./Authenticator.vue"),
  ArrowRightIcon: () => import("@toruslabs/vue-icons/arrows/ArrowRightIcon"),
  MobileIcon: () => import("@toruslabs/vue-icons/gadgets/MobileIcon"),
  DesktopIcon: () => import("@toruslabs/vue-icons/gadgets/DesktopIcon"),
  CheckCircleSolidIcon: () => import("@toruslabs/vue-icons/notifications/CheckCircleSolidIcon"),
  EyeOffSolidIcon: () => import("@toruslabs/vue-icons/security/EyeOffSolidIcon"),
  EyeSolidIcon: () => import("@toruslabs/vue-icons/security/EyeSolidIcon"),
  InformationCircleSolidIcon: () => import("@toruslabs/vue-icons/security/InformationCircleSolidIcon"),
  LockClosedIcon: () => import("@toruslabs/vue-icons/security/LockClosedIcon"),
  TrashIcon: () => import("@toruslabs/vue-icons/others/TrashIcon"),
  TrashSolidIcon: () => import("@toruslabs/vue-icons/others/TrashSolidIcon"),
  ExclamationCircleSolidIcon: () => import("@toruslabs/vue-icons/notifications/ExclamationCircleSolidIcon"),
  XIcon: () => import("@toruslabs/vue-icons/notifications/XIcon"),
  XSolidIcon: () => import("@toruslabs/vue-icons/notifications/XSolidIcon"),
  UploadIcon: () => import("@toruslabs/vue-icons/mail/UploadIcon"),
  ColorSwatchIcon: () => import("@toruslabs/vue-icons/file/ColorSwatchIcon"),
  DocumentIcon: () => import("@toruslabs/vue-icons/file/DocumentIcon"),
  DocumentTextIcon: () => import("@toruslabs/vue-icons/file/DocumentTextIcon"),
  DocumentDuplicateIcon: () => import("@toruslabs/vue-icons/file/DocumentDuplicateIcon"),
  PhoneIcon: () => import("@toruslabs/vue-icons/call/PhoneIcon"),
  ArrowCircleLeftIcon: () => import("@toruslabs/vue-icons/arrows/ArrowCircleLeftIcon"),
  ChevronDownSolidIcon: () => import("@toruslabs/vue-icons/arrows/ChevronDownSolidIcon"),
  ChevronUpSolidIcon: () => import("@toruslabs/vue-icons/arrows/ChevronUpSolidIcon"),
  ChevronRightSolidIcon: () => import("@toruslabs/vue-icons/arrows/ChevronRightSolidIcon"),
  ChevronRightIcon: () => import("@toruslabs/vue-icons/arrows/ChevronRightIcon"),
  CloudIcon: () => import("@toruslabs/vue-icons/software/CloudIcon"),
  DownloadIcon: () => import("@toruslabs/vue-icons/software/DownloadIcon"),
  UserCircleIcon: () => import("@toruslabs/vue-icons/users/UserCircleIcon"),
  MailIcon: () => import("@toruslabs/vue-icons/mail/MailIcon"),
  InformationCircleIcon: () => import("@toruslabs/vue-icons/security/InformationCircleIcon"),
  XCircleSolidIcon: () => import("@toruslabs/vue-icons/notifications/XCircleSolidIcon"),
  IdentificationIcon: () => import("@toruslabs/vue-icons/security/IdentificationIcon"),
  KeySolidIcon: () => import("@toruslabs/vue-icons/security/KeySolidIcon"),
  AndroidDarkIcon: () => import("./AndroidDark.vue"),
  AndroidIcon: () => import("./Android.vue"),
  WindowsDarkIcon: () => import("./WindowsDark.vue"),
  WindowsIcon: () => import("./Windows.vue"),
  AppleOsDarkIcon: () => import("./AppleDark.vue"),
  AppleOsIcon: () => import("./Apple.vue"),
  FingerPrintSolidIcon: () => import("@toruslabs/vue-icons/security/FingerPrintSolidIcon"),
  SwitchHorizontalIcon: () => import("@toruslabs/vue-icons/arrows/SwitchHorizontalIcon"),
  ShieldCheckIcon: () => import("@toruslabs/vue-icons/security/ShieldCheckIcon"),
  PasswordIcon: () => import("./Password.vue"),
  DeviceMultiIcon: () => import("./DeviceMulti.vue"),
  SmsSocialIcon: () => import("./SmsSocial.vue"),
  RecoveryIcon: () => import("./Recovery.vue"),
} as unknown as IconSets;
