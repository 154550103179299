import deepmerge from "deepmerge";
import log from "loglevel";
import { MutationPayload, Payload, Plugin, Store } from "vuex";

import { SkipVuexPersist } from "@/store/skipSync";
import { DAPP_MODULES_STORE_KEY, STORE_MODULES } from "@/utils/enums";
import { omit } from "@/utils/lodashUtils";

const defaultRestoreStateFn = (key: string, storage: Storage) => {
  try {
    const value = storage.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      return JSON.parse(value || "{}");
    }
    return value || {};
  } catch (error) {
    log.error("Error restoring state", error);
    return {};
  }
};

const defaultFilterFn = () => true;

const defaultSaveStateFn = (key: string, state: Record<string, unknown>, storage: Storage) => {
  try {
    storage.setItem(
      key, // Second argument is state _object_ if localforage, stringified otherwise
      JSON.stringify(state)
    );
  } catch (error) {
    log.error("Error saving state", error);
  }
};
const defaultReducerFn = <S>(state: unknown, moduleKey: string): Partial<S> => {
  return { [moduleKey]: (state as Record<string, unknown>)[moduleKey] } as Partial<S>;
};

export interface ModulePersistOptions<S> {
  /**
   * Window.Storage type object.
   */
  storage: Storage;

  /**
   * Method to retrieve state from persistence
   * @param key - Key to retrieve state from
   * @param storage - local or session storage
   */
  restoreState?: (key: string, storage: Storage) => S;

  /**
   * Method to save state into persistence
   * @param key - Key to save state to
   * @param state - State to save
   * @param storage - local or session storage
   */
  saveState?: (key: string, state: Record<string, unknown>, storage: Storage) => void;

  /**
   * Function to reduce state to the object you want to save.
   * Be default, we save the entire state.
   * You can use this if you want to save only a portion of it.
   * @param state - State to reduce
   */
  reducer?: (state: S, moduleKey: string) => Partial<S>;

  /**
   * Key to use to save the state into the storage
   */
  key: string;

  /**
   * Method to filter which mutations will trigger state saving
   * Be default returns true for all mutations.
   * Check mutations using <code>mutation.type</code>
   * @param mutation - object of type {@link Payload}
   */
  filter?: (mutation: Payload) => boolean;

  moduleName: string;
}

export interface VuexPersistModules<S> {
  [moduleName: string]: ModulePersistOptions<S>;
}

export default class VuexPersistence<S> {
  public modules: VuexPersistModules<S>;

  public subscribed: boolean;

  // Maintain a reference to store
  public store: Store<S> | null;

  public constructor() {
    this.subscribed = false;
    this.modules = {};
    this.store = null;
  }

  public addModule(moduleOptions: ModulePersistOptions<S>): void {
    if (!this.store) throw new Error("Install the plugin first");
    if (this.modules[moduleOptions.moduleName]) throw new Error("Module already installed");
    log.info("registering module", moduleOptions.moduleName);
    const { key } = moduleOptions;
    const { storage } = moduleOptions;
    moduleOptions.restoreState = moduleOptions.restoreState || defaultRestoreStateFn;
    moduleOptions.filter = moduleOptions.filter || defaultFilterFn;
    moduleOptions.saveState = moduleOptions.saveState || defaultSaveStateFn;
    moduleOptions.reducer = moduleOptions.reducer || defaultReducerFn;
    // register this module
    this.modules[moduleOptions.moduleName] = moduleOptions;

    const savedState = moduleOptions.restoreState(key, storage) as S;
    this.store.replaceState(deepmerge(this.store.state, savedState || {}) as S);
  }

  /**
   * The plugin function that can be used inside a vuex store.
   */
  plugin: Plugin<S> = (store: Store<S>) => {
    if (this.store) throw new Error("Plugin is singleton and already installed in store");
    this.store = store;
    this.subscriber(store)((mutation: MutationPayload, state: S) => {
      // get the module for mutation
      // check if it's in approved modules list
      // reduce the state to only include the module of the mutation
      const moduleName = mutation.type.split("/")[0];
      const currentModule = this.modules[moduleName];
      if (!currentModule || !currentModule.filter || !currentModule.reducer || !currentModule.saveState) return;

      if (currentModule.filter(mutation)) {
        const reducedState = currentModule.reducer(state, moduleName);
        const skipVuexPersist = SkipVuexPersist.getInstance();

        const finalState = reducedState as Record<string, Record<string, unknown>>;

        if (skipVuexPersist.skippedProps[moduleName]) {
          const omittedKeys = Object.keys(skipVuexPersist.skippedProps[moduleName]);
          finalState[moduleName] = omit(finalState[moduleName], omittedKeys);
          // special case for dapp module sub modules.
        } else if (!STORE_MODULES.includes(moduleName) && skipVuexPersist.skippedProps[DAPP_MODULES_STORE_KEY]) {
          const omittedKeys = Object.keys(skipVuexPersist.skippedProps[DAPP_MODULES_STORE_KEY]);
          finalState[moduleName] = omit(finalState[moduleName], omittedKeys);
        }

        currentModule.saveState(currentModule.key, finalState, currentModule.storage);
      }
    });

    this.subscribed = true;
  };

  /**
   * Creates a subscriber on the store. automatically is used
   * when this is used a vuex plugin. Not for manual usage.
   * @param store - Vuex store
   */
  private subscriber = (store: Store<S>) => (handler: (mutation: MutationPayload, state: S) => unknown) => store.subscribe(handler);
}
