import { CustomError } from "ts-custom-error";

import { ErrorAction, IAuthServiceError } from "@/utils/interfaces";

export abstract class AuthServiceError extends CustomError implements IAuthServiceError {
  code: number;

  message: string;

  actions: ErrorAction[];

  public constructor(code: number, message?: string, action?: ErrorAction[]) {
    // takes care of stack and proto
    super(message);

    this.code = code;
    this.message = message || "";
    this.actions = action || [];
    // Set name explicitly as minification can mangle class names
    Object.defineProperty(this, "name", { value: "AuthServiceError" });
  }

  toJSON(): IAuthServiceError {
    return {
      name: this.name,
      code: this.code,
      message: this.message,
    };
  }

  toString(): string {
    return JSON.stringify(this.toJSON());
  }
}
