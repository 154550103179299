import { KEY_TYPE, type TORUS_SAPPHIRE_NETWORK_TYPE } from "@toruslabs/constants";
import { LOGIN_TYPE } from "@toruslabs/customauth";
import { fetchLocalConfig } from "@toruslabs/fnd-base";
import type { UserType } from "@toruslabs/torus.js";
import {
  AUTH_ACTIONS,
  type AuthSessionConfig,
  type AuthSessionData,
  type ExtraLoginOptions,
  type LoginConfig,
  MFA_FACTOR,
  type MFA_FACTOR_TYPE,
  MFA_LEVELS,
  type MFA_SETTINGS,
  type MfaSettings,
  type OriginData,
  WEB3AUTH_NETWORK,
  WEB3AUTH_SAPPHIRE_NETWORK,
  type WhiteLabelData,
} from "@web3auth/auth";
import BN from "bn.js";
import deepmerge from "deepmerge";
import recursiveJSONParse from "json-stable-parse";
import log from "loglevel";
import URI from "urijs";

import useConfig from "@/composables/useConfig";
import { StartPageError } from "@/errors/startError";
import { dappModule } from "@/store/modules/dapp";
import loginPerf from "@/store/modules/loginPerf";
import userModule from "@/store/modules/user";
import { fetchDataFromStorageServer } from "@/utils/dappConfig";
import { REHYDRATE_LOGIN_CONFIG, REHYDRATE_SESSION_CONFIG, VALIDATE_FEATURE_ACCESS } from "@/utils/enums";
import { ERROR_TYPES } from "@/utils/errors";
import { LoginConfigItem } from "@/utils/interfaces";
import { getFinalKey, getPublicFromPrivateKey } from "@/utils/keys";
import { cloneDeep } from "@/utils/lodashUtils";
import { serializeError, validateFeatureAccess } from "@/utils/utils";

const { config, network, setNetwork, setMetadataHost, authVerifiers, isProdNetwork } = useConfig();

const getMetadataUrl = (sapphireNetwork: TORUS_SAPPHIRE_NETWORK_TYPE): string => {
  const nodeDetails = fetchLocalConfig(sapphireNetwork, KEY_TYPE.SECP256K1);
  return nodeDetails.torusNodeEndpoints[0].replace("/sss/jrpc", "/metadata");
};

const checkIfCustomVerifierUsed = (loginConfig: LoginConfig): boolean => {
  if (!loginConfig || Object.keys(loginConfig).length === 0) return false;

  const isCustomVerifier = (key: string): boolean => {
    const currentConfig = loginConfig[key];
    if (!currentConfig || !currentConfig.verifier) return false;
    if (authVerifiers.value.includes(currentConfig.verifier)) {
      return false;
    }
    return true;
  };

  return Boolean(Object.keys(loginConfig).find(isCustomVerifier));
};

const checkIfWhitelabelPresent = (whitelabel: WhiteLabelData): boolean => {
  if (!whitelabel || Object.keys(whitelabel).length === 0) return false;
  const localWhiteLabelData = cloneDeep(whitelabel || {});
  const whitelabelKeys = ["appName", "appUrl", "logoDark", "logoLight", "theme", "tncLink", "privacyPolicy"];
  // check if these keys are defined and if object its not an empty object.
  const isKeyPresent = (key: string): boolean => {
    const value = localWhiteLabelData[key as keyof WhiteLabelData];
    if (!value) return false;
    if (typeof value === "object" && Object.keys(value).length === 0) return false;
    return true;
  };

  return localWhiteLabelData && whitelabelKeys.some(isKeyPresent);
};

const fetchAuthSessionConfig = async (loginId: string, sessionNamespace: string): Promise<AuthSessionConfig> => {
  const localResult = await userModule.getLoginConfig({ loginId, sessionNamespace });
  if (localResult) return localResult;
  const data = await fetchDataFromStorageServer<AuthSessionConfig>(loginId, sessionNamespace, true);
  userModule.updateCacheLoginConfig({ loginId, sessionNamespace, authSessionConfig: data });
  return data;
};

const monkeyPatchFetch = () => {
  const { fetch: originalFetch } = globalThis;
  globalThis.fetch = (...args) => {
    const [resource, requestConfig] = args;
    const parsedUrl = typeof resource === "string" ? new URL(resource) : (resource as URL);
    if (!parsedUrl.pathname.includes("/metadata")) return originalFetch(...args);

    if (!config) {
      return originalFetch(resource as URL, { headers: { "x-w3a-client-id": dappModule.clientId } });
    }
    if (requestConfig.headers instanceof Headers) requestConfig.headers.append("x-w3a-client-id", dappModule.clientId);
    else if (Array.isArray(requestConfig.headers)) requestConfig.headers.push(["x-w3a-client-id", dappModule.clientId]);
    else requestConfig.headers = { ...(requestConfig.headers || {}), "x-w3a-client-id": dappModule.clientId };
    return originalFetch(resource as URL, requestConfig);
  };
};

export const rehydrateLoginConfig = async (validateFeatures = false): Promise<void> => {
  const operationName = REHYDRATE_LOGIN_CONFIG;
  window.performance.mark(`${operationName}_start`);

  if (dappModule.rehydrated) return;
  const { loginId, sessionNamespace } = userModule;
  if (!loginId) {
    throw new Error("Login identifier missing.");
  }

  const loginConfig = await fetchAuthSessionConfig(loginId, sessionNamespace);

  await loginPerf.markRouteAndTime({
    operation: operationName,
  });

  log.info("login config", loginConfig);
  const { options, params, actionType, sessionId } = loginConfig;

  const dappUrl = new URI(params.redirectUrl || options.redirectUrl);
  dappModule.setSiteMetadata({ name: dappUrl.hostname(), url: dappUrl.origin() });

  await setNetwork(options.network || WEB3AUTH_NETWORK.SAPPHIRE_MAINNET);

  // get the metadata host url here for sapphire networks.
  if (Object.values(WEB3AUTH_SAPPHIRE_NETWORK).includes(network.value as TORUS_SAPPHIRE_NETWORK_TYPE)) {
    const networkUrl = getMetadataUrl(network.value as TORUS_SAPPHIRE_NETWORK_TYPE);
    setMetadataHost(networkUrl);
    monkeyPatchFetch();
  }

  const localLoginConfig = recursiveJSONParse(options.loginConfig) as LoginConfig;

  const localWhiteLabel: WhiteLabelData = recursiveJSONParse(options.whiteLabel);

  // fetch project config if whitelabel is missing or sms_passwordless config is missing.
  // if (!checkIfWhitelabelPresent(localWhiteLabel) || !localLoginConfig.sms_passwordless) {
  //   const data = await fetchProjectConfig(options.clientId);

  //   // checking whitelabel again as its inside an or conditional block.
  //   if (data && !checkIfWhitelabelPresent(localWhiteLabel)) localWhiteLabel = data.whitelabel;

  //   // check if sms_passwordless is missing in loginConfig
  //   if (data && !localLoginConfig.sms_passwordless && typeof data.sms_otp_enabled === "boolean") {
  //     localLoginConfig.sms_passwordless = {
  //       showOnModal: data.sms_otp_enabled,
  //       showOnDesktop: data.sms_otp_enabled,
  //       showOnMobile: data.sms_otp_enabled,
  //       showOnSocialBackupFactor: data.sms_otp_enabled,
  //     } as LoginConfig[keyof LoginConfig];
  //   }
  // }

  if (localWhiteLabel) {
    dappModule.setWhiteLabel(localWhiteLabel);
  }
  // set whitelabel loaded to true if whitelabel is missing as well.
  // This is to load the components with default whitelabel settings.
  dappModule.setWhiteLabelLoaded();

  // relogin the user if sessionId is valid.
  // This is used in the case of enable mfa, manage mfa, modify social factor.
  if (sessionId) {
    const opName = REHYDRATE_SESSION_CONFIG;
    window.performance.mark(`${opName}_start`);
    const sessionData = await fetchDataFromStorageServer<AuthSessionData>(sessionId, sessionNamespace);
    if (!sessionData) {
      log.warn("Session Id expired");
      throw StartPageError.invalidLoginSession("No active session found. Please login again");
    } else {
      log.info("session data", sessionData);
      const { userInfo, signatures, metadataNonce, keyMode, tKey } = sessionData as AuthSessionData;
      if (!userModule.sessionId) userModule.setSessionId(sessionId);
      if (userInfo) {
        userModule.setUserInfo({
          email: userInfo.email as string,
          name: userInfo.name as string,
          profileImage: userInfo.profileImage as string,
          verifierId: userInfo.verifierId as string,
          verifier: userInfo.verifier as string,
          typeOfLogin: userInfo.typeOfLogin as LOGIN_TYPE,
          aggregateVerifier: (userInfo.aggregateVerifier || userInfo.verifier) ?? "",
          idToken: userInfo.idToken as string,
        });
      }
      if (
        actionType === AUTH_ACTIONS.ADD_SOCIAL_FACTOR ||
        actionType === AUTH_ACTIONS.MODIFY_SOCIAL_FACTOR ||
        actionType === AUTH_ACTIONS.ADD_AUTHENTICATOR_FACTOR ||
        actionType === AUTH_ACTIONS.ADD_PASSKEY_FACTOR
      ) {
        const { X: oAuthKeyX, Y: oAuthKeyY, address: OAuthAddress } = getPublicFromPrivateKey(sessionData.oAuthPrivateKey as string);
        const finalPrivKey = userInfo?.isMfaEnabled ? tKey : getFinalKey(sessionData.oAuthPrivateKey as string, sessionData.metadataNonce);
        const { X, Y, address } = finalPrivKey ? getPublicFromPrivateKey(finalPrivKey) : { X: "", Y: "", address: "" };
        const typeOfUser = keyMode === "v1" ? "v1" : "v2";
        const keyInfo = {
          keyData: {
            privKey: typeOfUser === "v2" ? sessionData.oAuthPrivateKey : finalPrivKey || sessionData.oAuthPrivateKey,
            X: typeOfUser === "v2" ? oAuthKeyX : X || oAuthKeyX,
            Y: typeOfUser === "v2" ? oAuthKeyY : Y || oAuthKeyY,
            walletAddress: typeOfUser === "v2" ? OAuthAddress : address || OAuthAddress,
          },
          oAuthKeyData: {
            privKey: sessionData.oAuthPrivateKey as string,
            X: oAuthKeyX,
            Y: oAuthKeyY,
            walletAddress: OAuthAddress,
          },
          finalKeyData: {
            privKey: finalPrivKey,
            X,
            Y,
            walletAddress: address,
          },
          userInfo: {
            email: userInfo.email as string,
            name: userInfo.name as string,
            profileImage: userInfo.profileImage as string,
            verifierId: userInfo.verifierId as string,
            verifier: userInfo.verifier as string,
            typeOfLogin: userInfo.typeOfLogin as LOGIN_TYPE,
            aggregateVerifier: (userInfo.aggregateVerifier || userInfo.verifier) ?? "",
            accessToken: "",
            state: {},
            idToken: userInfo.idToken as string,
          },
          sessionData: {
            sessionTokenData: signatures.map((i) => {
              const { data, sig } = JSON.parse(i);
              return { token: data, signature: sig, node_pubx: "", node_puby: "" };
            }),
            sessionAuthKey: "",
          },
          metadata: {
            upgraded: userInfo.isMfaEnabled,
            typeOfUser: (sessionData.keyMode === "v1" ? "v1" : "v2") as UserType,
            nonce: new BN(metadataNonce, "hex"),
            serverTimeOffset: 0,
          },
          nodesData: {
            nodeIndexes: [1, 2, 3, 4, 5],
          },
        };
        userModule.setKeyInfo(keyInfo);
        userModule.setPostboxKey(sessionData.oAuthPrivateKey);
        userModule.setAuthToken(sessionData.authToken);
        userModule.setKeyMode(sessionData.keyMode);
        if (sessionData.userInfo?.isMfaEnabled) {
          const tKeyModule = (await import("@/store/modules/tkey")).default;
          await tKeyModule.login({
            postboxKey: sessionData.oAuthPrivateKey as string,
            dappShare: sessionData.userInfo.dappShare,
            shareStores: [],
          });
        }
      }
    }
    await loginPerf.markRouteAndTime({
      operation: opName,
    });
  }

  if (validateFeatures) {
    const opName = VALIDATE_FEATURE_ACCESS;
    window.performance.mark(`${opName}_start`);
    // check if whitelabel and sessionTime features are gated
    const isWhiteLabel = checkIfWhitelabelPresent(localWhiteLabel);
    const isCustomSessionTime = (options.sessionTime ?? 0) > 86400;
    const isCustomAuth = checkIfCustomVerifierUsed(localLoginConfig);
    const isMfaSettings = Boolean(options.mfaSettings && Object.keys(options.mfaSettings).length > 0);
    if (isWhiteLabel || isCustomSessionTime || isCustomAuth || isProdNetwork.value) {
      try {
        await validateFeatureAccess(options.clientId, isWhiteLabel, options.sessionTime ?? 86400, isCustomAuth, isMfaSettings);
      } catch (error: unknown) {
        const err = await serializeError(error);
        throw StartPageError.invalidParams(err.message);
      }
    }
    await loginPerf.markRouteAndTime({
      operation: opName,
    });
  }

  dappModule.setDappParams({
    sessionTime: options.sessionTime,
    clientId: options.clientId,
    uxMode: options.uxMode,
    originData: recursiveJSONParse(options.originData) as OriginData,
    redirectUrl: params.redirectUrl || options.redirectUrl,
    actionType,
    useCoreKitKey: options.useCoreKitKey,
  });
  if (localLoginConfig) {
    Object.keys(localLoginConfig).forEach((y) => {
      dappModule.modifyCustomLoginConfig({
        loginProvider: y,
        cfg: (localLoginConfig || {})[y] as LoginConfigItem,
      });
    });
  }

  if (options.mfaSettings) {
    let localMfaSettings = recursiveJSONParse(options.mfaSettings) as MfaSettings;
    localMfaSettings = deepmerge(config.value?.mfaSettings, localMfaSettings);
    if (params.mfaLevel !== MFA_LEVELS.NONE) {
      const enabledFactors = Object.values(localMfaSettings).filter((factor: MFA_SETTINGS) => factor.enable);
      if (enabledFactors.length === 0) throw new Error(ERROR_TYPES.NO_MFA_FACTOR_ENABLED);
      const mandatoryFactors = enabledFactors.filter((factor: MFA_SETTINGS) => factor.mandatory);
      if (mandatoryFactors.length === 0) throw new Error(ERROR_TYPES.MFA_FACTOR_MANDATORY);

      // need to have atleast one mandatory mfa method
      // device and passkeys are zero weighted factors.
      // so neeed to have atleast one mandatory factor other than device and passkeys.
      const one = Object.keys(localMfaSettings).some((x: string) => {
        const currentSetting = localMfaSettings[x as MFA_FACTOR_TYPE];
        return currentSetting.mandatory && currentSetting.enable && x !== MFA_FACTOR.DEVICE && x !== MFA_FACTOR.PASSKEYS;
      });
      if (!one) {
        throw new Error(ERROR_TYPES.MFA_FACTOR_MANDATORY);
      }
    }
    dappModule.setMfaSettings(localMfaSettings);
  }

  const localExtraLoginOptions = recursiveJSONParse(params.extraLoginOptions) as ExtraLoginOptions;
  if (localExtraLoginOptions?.additionalParams) {
    const additionalParams = cloneDeep(localExtraLoginOptions.additionalParams) as Record<string, string>;
    Object.keys(additionalParams).forEach((x) => {
      if (additionalParams[x]) {
        localExtraLoginOptions[x] = additionalParams[x] as string;
      }
    });
    delete localExtraLoginOptions.additionalParams;
  }

  if (!params.loginProvider) throw new Error("loginProvider is missing in params");
  dappModule.setLoginParams({
    currentLoginProvider: params.loginProvider,
    mfaLevel: params.mfaLevel,
    getWalletKey: params.getWalletKey,
    appState: params.appState,
    dappShare: params.dappShare,
    curve: params.curve,
    extraLoginOptions: localExtraLoginOptions,
  });

  dappModule.setLoginConfigHydrated(true);
};
