import type { Span } from "@sentry/types";
import log from "loglevel";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { type AUTH_FACTOR_TYPE, LOGIN_PERF_MODULE_KEY, SESSION_STORAGE_KEY } from "@/utils/enums";
import { ILoginPerfModuleState, UserLoginRecord } from "@/utils/interfaces";
import { getSentryInstance } from "@/utils/sentry";
import { canPreserveState, convertToMilliseconds, measurePerformance, measurePerformanceAndRestart } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";

let sentryTx: null | Span = null;

@Module({
  namespaced: true,
  name: LOGIN_PERF_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(LOGIN_PERF_MODULE_KEY, SESSION_STORAGE_KEY),
})
class LoginPerfModule extends VuexModule implements ILoginPerfModuleState {
  public currentLoginPath = "";

  public lastRoute = "";

  public totalTimeTaken = 0;

  public totalMfaTimeTaken = 0;

  public loginRecordId = "";

  public dappId = 0;

  public authFactorsUsed: AUTH_FACTOR_TYPE[] = [];

  public localLoginMetadata: Partial<UserLoginRecord> = {};

  @Mutation
  _reinit() {
    this.currentLoginPath = "";
    this.loginRecordId = "";
    this.lastRoute = "";
    this.totalMfaTimeTaken = 0;
    this.authFactorsUsed = [];
    this.localLoginMetadata = {};
    this.totalTimeTaken = 0;
  }

  @Mutation
  resetDappId() {
    this.dappId = 0;
    this.authFactorsUsed = [];
  }

  @Mutation
  incrementTotalTime(timeTaken: number) {
    this.totalTimeTaken += timeTaken;
  }

  @Mutation
  resetTotalTime() {
    this.totalTimeTaken = 0;
  }

  @Mutation
  setLastRotue(route: string) {
    this.lastRoute = route;
    if (this.lastRoute !== route) this.currentLoginPath = this.currentLoginPath ? `${this.currentLoginPath}|${route}` : route;
  }

  @Mutation
  incrementTotalMfaTime(operationName: string) {
    const timeTaken = measurePerformance(operationName);
    this.totalTimeTaken += timeTaken;
    this.totalMfaTimeTaken += timeTaken;
  }

  @Action
  public async markRouteAndTime(params: {
    route?: string;
    transactionName?: string;
    restartPerfMeasurement?: boolean;
    isEnd?: boolean;
    operation?: string;
  }) {
    const Sentry = await getSentryInstance();
    const { route, isEnd, operation, restartPerfMeasurement } = params;
    let timeTaken = 0;
    if (restartPerfMeasurement && operation) {
      timeTaken = measurePerformanceAndRestart(operation);
    } else if (operation) {
      timeTaken = measurePerformance(operation);
    }
    if (route) {
      this.setLastRotue(route);
    }
    this.incrementTotalTime(timeTaken);

    try {
      if (!sentryTx) {
        // end the top level span, which is the page load span.
        // This is to avoid the page load span to be too long.
        // Also, when we end this we can have a clean state.
        const activeSpan = Sentry.getActiveSpan();
        const rootSpan = activeSpan && Sentry.getRootSpan(activeSpan);
        if (rootSpan) {
          rootSpan.end();
        }

        sentryTx = Sentry.startInactiveSpan({
          name: params.transactionName || "login",
          forceTransaction: true,
          op: "login",
        });
      }

      if (sentryTx && operation) {
        const childSpan = Sentry.startInactiveSpan({
          name: operation,
          startTime: Number.parseFloat((Date.now() - timeTaken).toFixed(3)),
          parentSpan: sentryTx,
        });
        childSpan.end();
      }

      if (isEnd && sentryTx) {
        const parsedSpan = Sentry.spanToJSON(sentryTx);
        const endTime = convertToMilliseconds(parsedSpan.start_timestamp) + this.totalTimeTaken;
        if (sentryTx?.end) {
          sentryTx.end(Number.parseFloat(endTime.toFixed(3)));
        }
        sentryTx = null;
        this.resetTotalTime();
      }
    } catch (error) {
      log.error("error while recording perf in sentry", error);
    }
  }

  @Mutation
  addAuthFactor(authFactor: AUTH_FACTOR_TYPE) {
    if (this.authFactorsUsed.indexOf(authFactor) < 0) this.authFactorsUsed.push(authFactor);
  }

  @Action
  addLocalMetadataState(params: {
    loginRoute?: string;
    errorStack?: string;
    hasSkippedTkey?: boolean;
    shareIndex?: string;
    walletAddress?: string;
    hasSkippedMfa?: boolean;
  }) {
    const { errorStack, hasSkippedTkey, loginRoute, shareIndex, hasSkippedMfa } = params;

    const finalLocalState = this.localLoginMetadata;
    if (loginRoute) {
      if (!finalLocalState.login_route) finalLocalState.login_route = loginRoute;
      else if (!finalLocalState.login_route.includes(loginRoute)) finalLocalState.login_route = finalLocalState.login_route.concat("|", loginRoute);
    }
    if (errorStack) finalLocalState.error_stack = errorStack;
    if (shareIndex) finalLocalState.share_index = shareIndex;
    if (typeof hasSkippedTkey === "boolean") finalLocalState.has_skipped_tkey = hasSkippedTkey;
    if (typeof hasSkippedMfa === "boolean") finalLocalState.has_skipped_mfa = hasSkippedMfa;

    this.updateState({ localLoginMetadata: finalLocalState });
  }

  @Mutation
  public updateState(state: { currentLoginPath?: string; loginRecordId?: string; dappId?: number; localLoginMetadata: Partial<UserLoginRecord> }) {
    const { currentLoginPath, loginRecordId, dappId, localLoginMetadata } = state;
    if (currentLoginPath) this.currentLoginPath = currentLoginPath;
    if (loginRecordId) this.loginRecordId = loginRecordId;
    if (dappId) this.dappId = dappId;
    if (localLoginMetadata) this.localLoginMetadata = localLoginMetadata;
  }

  @Action
  updateSentryRootTransactionName({ name }: { name: string }) {
    if (sentryTx) {
      sentryTx.updateName(name);
    }
  }
}

const loginPerfModule = getModule(LoginPerfModule);

installStorePlugin({
  key: LOGIN_PERF_MODULE_KEY,
  saveState: (key: string, state: Record<string, unknown>, storage?: Storage) => {
    storage?.setItem(key, JSON.stringify(state));
  },
  restoreState: (key: string, storage?: Storage) => {
    const value = storage?.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      const parsedValue = JSON.parse(value || "{}");
      return {
        [LOGIN_PERF_MODULE_KEY]: parsedValue[LOGIN_PERF_MODULE_KEY],
      };
    }
    return value || {};
  },
  storage: SESSION_STORAGE_KEY,
});

export default loginPerfModule;
