<script setup lang="ts">
import { Icon } from "@toruslabs/vue-components/Icon";
import log from "loglevel";
import { computed, defineAsyncComponent, onBeforeMount, onUnmounted, watch } from "vue";
import { RouterView, useRoute } from "vue-router";

import Toast from "./containers/Toast";
import { rehydrateLoginConfig } from "./sdk/rehydrateLoginConfig";
import { dappModule } from "./store/modules/dapp";
import userModule from "./store/modules/user";

const TraceError = defineAsyncComponent(() => import("./containers/TraceError"));

const route = useRoute();

const showError = computed(() => {
  return userModule.showTraceErrorModal;
});

const showErrorTraceModalButton = computed(() => {
  return userModule.errorTraceMessageList.length > 0;
});

onBeforeMount(async () => {
  try {
    userModule.setTraceErrorModal(false);
    dappModule.setWhiteLabel(dappModule.whiteLabel);
  } catch (error) {
    log.error("app create error", error);
  }
});

watch(
  () => route.meta.rehydrate,
  async () => {
    if (userModule.loginId && route.meta.rehydrate && !dappModule.rehydrated) {
      await rehydrateLoginConfig();
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  userModule.setTraceErrorModal(false);
});

const setTraceErrorModal = (isModalOpen: boolean): void => {
  userModule.setTraceErrorModal(isModalOpen);
};
</script>

<template>
  <RouterView />
  <Icon
    v-if="showErrorTraceModalButton"
    name="exclamation-circle-solid-icon"
    class="absolute z-50 top-3 right-3 cursor-pointer text-app-red-400"
    @click="setTraceErrorModal(true)"
  />
  <!-- TraceError -->
  <Suspense v-if="showError">
    <TraceError />
    <template #fallback>
      <p>Loading....</p>
    </template>
  </Suspense>
  <!-- Toaster -->
  <Toast />
</template>

<style scoped></style>
