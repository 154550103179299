import { SESSION_SERVER_API_URL, TORUS_SAPPHIRE_NETWORK, TORUS_SAPPHIRE_NETWORK_TYPE } from "@toruslabs/constants";
import { LOGIN } from "@toruslabs/customauth";
import { type BUILD_ENV_TYPE, MFA_FACTOR, MfaSettings, storageAvailable, WEB3AUTH_NETWORK, type WEB3AUTH_NETWORK_TYPE } from "@web3auth/auth";
import bowser from "bowser";
import { computed, Ref, ref, watch } from "vue";

import browserInfo from "@/browserConfig";
import {
  APPLE,
  APPLE_LOGIN_PROVIDER,
  AUTHENTICATOR_LOGIN_PROVIDER,
  DISCORD,
  DISCORD_LOGIN_PROVIDER,
  EMAIL_FLOW,
  EMAIL_PASSWORDLESS_LOGIN_PROVIDER,
  FACEBOOK,
  FACEBOOK_LOGIN_PROVIDER,
  FARCASTER_LOGIN_PROVIDER,
  GITHUB,
  GITHUB_LOGIN_PROVIDER,
  GOOGLE,
  GOOGLE_LOGIN_PROVIDER,
  JWT,
  KAKAO_LOGIN_PROVIDER,
  LINE,
  LINE_LOGIN_PROVIDER,
  LINKEDIN,
  LINKEDIN_LOGIN_PROVIDER,
  LOCAL_STORAGE_KEY,
  PASSKEYS,
  PASSKEYS_LOGIN_PROVIDER,
  REDDIT,
  REDDIT_LOGIN_PROVIDER,
  SESSION_STORAGE_KEY,
  SMS_PASSWORDLESS_LOGIN_PROVIDER,
  TWITCH,
  TWITCH_LOGIN_PROVIDER,
  TWITTER,
  TWITTER_LOGIN_PROVIDER,
  WECHAT_LOGIN_PROVIDER,
} from "@/utils/enums";
import { AppConfig, LoginConfig, LoginConfigItem } from "@/utils/interfaces";

import buildEnv from "../config-build";
import configEnv from "../config-env";

const environment = import.meta.env.VITE_BUILD_ENV as BUILD_ENV_TYPE;
const network: Ref<WEB3AUTH_NETWORK_TYPE> = ref(WEB3AUTH_NETWORK.SAPPHIRE_MAINNET);
const metadataHost: Ref<string> = ref(configEnv[network.value].metadataHost);

const setNetwork = async (networkType: WEB3AUTH_NETWORK_TYPE) => {
  network.value = networkType;
};

const setMetadataHost = (metadataHostUrl: string) => {
  metadataHost.value = metadataHostUrl;
};

watch(network, (newNetwork: WEB3AUTH_NETWORK_TYPE, oldNetwork: WEB3AUTH_NETWORK_TYPE) => {
  // This is okay because setNetwork is only called once
  // we dont want to set it for sapphire networks, just a safe check here.
  if (newNetwork !== oldNetwork && !Object.values(TORUS_SAPPHIRE_NETWORK).includes(newNetwork as TORUS_SAPPHIRE_NETWORK_TYPE)) {
    setMetadataHost(configEnv[newNetwork].metadataHost);
  }
});

const useConfig = () => {
  const config = computed<AppConfig>(() => {
    const currentConfigEnv = configEnv[network.value];
    const currentBuildEnv = buildEnv[environment];
    if (!currentConfigEnv || !currentBuildEnv) {
      throw new Error("Invalid environment settings");
    }

    return {
      version: (import.meta.env.VITE_BUILD_VERSION ?? "").split(".")[0],
      appVersion: import.meta.env.VITE_APP_VERSION ? Number.parseInt(import.meta.env.VITE_APP_VERSION.split(".")[0], 10) : 0,
      signerHost: currentConfigEnv.signerHost,
      apiHost: currentBuildEnv.apiHost,
      passwordlessBackend: currentBuildEnv.passwordlessBackendHost,
      passwordlessHost: currentBuildEnv.passwordlessHost,
      storageServerUrl: SESSION_SERVER_API_URL,
      tkeyEmail: {
        host: "https://api.web3auth.io/tkey-email-service/send_mail",
        logo: "https://images.web3auth.io/web3auth-logo-passwordless-email.png",
        name: "Web3Auth",
      },
      alwaysAllowedHosts: ["localhost", "127.0.0.1", "sdk.openlogin.com", "demo-openlogin.web3auth.io", "web3auth.vercel.app"],
      allowedDashboardHosts: [
        "localhost",
        "web3auth.vercel.app",
        "develop-account.web3auth.io",
        "staging-account.web3auth.io",
        "account.web3auth.io",
      ],
      supportsVersioning: currentBuildEnv.supportsVersioning,
      redirectPath: `/auth`.slice(1),
      sentrySampleRate: currentBuildEnv.sentrySampleRate,
      sentryTransactionSampleRate: currentBuildEnv.sentryTransactionSampleRate,
      isStorageAvailable: {
        [LOCAL_STORAGE_KEY]: storageAvailable(LOCAL_STORAGE_KEY),
        [SESSION_STORAGE_KEY]: storageAvailable(SESSION_STORAGE_KEY),
      },
      prodNetworks: [
        WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        WEB3AUTH_NETWORK.MAINNET,
        WEB3AUTH_NETWORK.CYAN,
        WEB3AUTH_NETWORK.AQUA,
        WEB3AUTH_NETWORK.CELESTE,
      ],
      mfaSettings: {
        [MFA_FACTOR.DEVICE]: {
          enable: true,
          priority: 1,
          mandatory: true,
        },
        [MFA_FACTOR.SOCIAL_BACKUP]: {
          enable: true,
          priority: 4,
          mandatory: false,
        },
        [MFA_FACTOR.BACKUP_SHARE]: {
          enable: true,
          priority: 5,
          mandatory: false,
        },
        [MFA_FACTOR.PASSWORD]: {
          enable: true,
          priority: 6,
          mandatory: false,
        },
        [MFA_FACTOR.PASSKEYS]: {
          enable: true,
          priority: 3,
          mandatory: false,
        },
        [MFA_FACTOR.AUTHENTICATOR]: {
          enable: true,
          priority: 2,
          mandatory: true,
        },
      } as MfaSettings,
      logLevel: currentBuildEnv.logLevel,
      // key is the login provider
      loginConfig: {
        [GOOGLE_LOGIN_PROVIDER]: {
          loginProvider: GOOGLE_LOGIN_PROVIDER,
          verifier: currentConfigEnv.googleVerifier,
          typeOfLogin: GOOGLE,
          name: GOOGLE,
          description: "login.verifier-google-desc",
          clientId: currentConfigEnv.googleClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          showOnModal: true,
          mainOption: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletGoogleVerifier,
          jwtParameters: {
            prompt: browserInfo.platform === bowser.PLATFORMS_MAP.desktop ? "select_account" : "consent select_account",
          },
        } as LoginConfigItem,
        [FACEBOOK_LOGIN_PROVIDER]: {
          loginProvider: FACEBOOK_LOGIN_PROVIDER,
          verifier: currentConfigEnv.facebookVerifier,
          description: "",
          typeOfLogin: FACEBOOK,
          name: FACEBOOK,
          clientId: currentConfigEnv.facebookClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          showOnModal: true,
          mainOption: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletFacebookVerifier,
        } as LoginConfigItem,
        [TWITTER_LOGIN_PROVIDER]: {
          loginProvider: TWITTER_LOGIN_PROVIDER,
          verifier: currentConfigEnv.twitterVerifier,
          description: "",
          typeOfLogin: TWITTER,
          name: TWITTER,
          clientId: currentConfigEnv.twitterClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          showOnModal: true,
          mainOption: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "twitter",
            isVerifierIdCaseSensitive: false,
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletTwitterVerifier,
        } as LoginConfigItem,
        [DISCORD_LOGIN_PROVIDER]: {
          loginProvider: DISCORD_LOGIN_PROVIDER,
          verifier: currentConfigEnv.discordVerifier,
          description: "",
          typeOfLogin: DISCORD,
          name: DISCORD,
          clientId: currentConfigEnv.discordClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          showOnModal: true,
          mainOption: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.discordVerifier,
        } as LoginConfigItem,
        [LINE_LOGIN_PROVIDER]: {
          loginProvider: LINE_LOGIN_PROVIDER,
          verifier: currentConfigEnv.lineVerifier,
          description: "",
          typeOfLogin: LINE,
          name: "LINE",
          clientId: currentConfigEnv.lineClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "line",
            prompt: "consent",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletLineVerifier,
        } as LoginConfigItem,
        [REDDIT_LOGIN_PROVIDER]: {
          loginProvider: REDDIT_LOGIN_PROVIDER,
          verifier: currentConfigEnv.redditVerifier,
          description: "",
          typeOfLogin: JWT,
          name: REDDIT,
          clientId: currentConfigEnv.redditClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            verifierIdField: "name",
            connection: "Reddit",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletRedditVerifier,
        } as LoginConfigItem,
        [APPLE_LOGIN_PROVIDER]: {
          loginProvider: APPLE_LOGIN_PROVIDER,
          verifier: currentConfigEnv.appleVerifier,
          description: "",
          typeOfLogin: APPLE,
          name: APPLE,
          clientId: currentConfigEnv.appleClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "apple",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletAppleVerifier,
        } as LoginConfigItem,
        [GITHUB_LOGIN_PROVIDER]: {
          loginProvider: GITHUB_LOGIN_PROVIDER,
          verifier: currentConfigEnv.githubVerifier,
          description: "",
          typeOfLogin: GITHUB,
          name: "GitHub",
          clientId: currentConfigEnv.githubClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "github",
            isVerifierIdCaseSensitive: false,
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletGithubVerifier,
        } as LoginConfigItem,
        [TWITCH_LOGIN_PROVIDER]: {
          loginProvider: TWITCH_LOGIN_PROVIDER,
          verifier: currentConfigEnv.twitchVerifier,
          description: "",
          typeOfLogin: TWITCH,
          name: TWITCH,
          clientId: currentConfigEnv.twitchClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletTwitchVerifier,
        } as LoginConfigItem,
        [LINKEDIN_LOGIN_PROVIDER]: {
          loginProvider: LINKEDIN_LOGIN_PROVIDER,
          verifier: currentConfigEnv.linkedinVerifier,
          description: "",
          typeOfLogin: LINKEDIN,
          name: "LinkedIn",
          clientId: currentConfigEnv.linkedinClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "linkedin",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletLinkedinVerifier,
        } as LoginConfigItem,
        [WECHAT_LOGIN_PROVIDER]: {
          loginProvider: WECHAT_LOGIN_PROVIDER,
          verifier: currentConfigEnv.wechatVerifier,
          description: "",
          typeOfLogin: JWT,
          name: "WeChat",
          clientId: currentConfigEnv.wechatClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: false,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "Wechat",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletWechatVerifier,
        } as LoginConfigItem,
        [KAKAO_LOGIN_PROVIDER]: {
          loginProvider: KAKAO_LOGIN_PROVIDER,
          verifier: currentConfigEnv.kakaoVerifier,
          description: "",
          typeOfLogin: JWT,
          name: "Kakao",
          clientId: currentConfigEnv.kakaoClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "Kakao",
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: currentConfigEnv.walletKakaoVerifier,
        } as LoginConfigItem,
        [EMAIL_PASSWORDLESS_LOGIN_PROVIDER]: {
          loginProvider: EMAIL_PASSWORDLESS_LOGIN_PROVIDER,
          verifier: currentConfigEnv.hostedEmailPasswordlessVerifier,
          description: "login.verifier-email-desc",
          typeOfLogin: LOGIN.EMAIL_PASSWORDLESS,
          name: "email",
          clientId: currentConfigEnv.hostedEmailPasswordlessClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            verifierIdField: "name",
            isVerifierIdCaseSensitive: false,
            flow_type: EMAIL_FLOW.code,
          },
          // For torus only
          buttonDescription: "Sign up/in with Email",
          walletVerifier: currentConfigEnv.walletHostedEmailPasswordlessVerifier,
        } as LoginConfigItem,
        [SMS_PASSWORDLESS_LOGIN_PROVIDER]: {
          loginProvider: SMS_PASSWORDLESS_LOGIN_PROVIDER,
          verifier: currentConfigEnv.hostedSmsPasswordlessVerifier,
          description: "login.verifier-sms-desc-2",
          typeOfLogin: LOGIN.SMS_PASSWORDLESS,
          name: "mobile",
          clientId: currentConfigEnv.hostedSmsPasswordlessClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            verifierIdField: "name",
            isVerifierIdCaseSensitive: false,
          },

          // for torus only.
          buttonDescription: "Sign up/in with Mobile",
          walletVerifier: currentConfigEnv.walletHostedSmsPasswordlessVerifier,
        } as LoginConfigItem,
        [PASSKEYS_LOGIN_PROVIDER]: {
          loginProvider: PASSKEYS_LOGIN_PROVIDER,
          verifier: currentConfigEnv.passkeysVerifier,
          description: "login.verifier-webauth-desc",
          typeOfLogin: PASSKEYS,
          name: "passkey",
          clientId: currentConfigEnv.passkeysClientId,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: false,
          showOnDesktop: false,
          showOnMobile: false,
          showOnSocialBackupFactor: false,
          // For torus only
          buttonDescription: "",
          walletVerifier: "",
        } as LoginConfigItem,
        [FARCASTER_LOGIN_PROVIDER]: {
          loginProvider: FARCASTER_LOGIN_PROVIDER,
          verifier: currentConfigEnv.hostedFarcasterVerifier,
          description: "",
          typeOfLogin: JWT,
          name: "Farcaster",
          clientId: currentConfigEnv.hostedFarcasterClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.farcasterLoginDomain,
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: "",
        } as LoginConfigItem,
        [AUTHENTICATOR_LOGIN_PROVIDER]: {
          loginProvider: AUTHENTICATOR_LOGIN_PROVIDER,
          verifier: currentConfigEnv.authenticatorVerifier,
          description: "",
          typeOfLogin: JWT,
          name: "Authenticator",
          clientId: currentConfigEnv.authenticatorClientId,
          verifierSubIdentifier: currentConfigEnv.verifierSubIdentifier,
          logoHover: "",
          logoLight: "",
          logoDark: "",
          mainOption: false,
          showOnModal: false,
          showOnDesktop: false,
          showOnMobile: false,
          showOnSocialBackupFactor: false,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            verifierIdField: "name",
            connection: "authenticator",
            isVerifierIdCaseSensitive: false,
            network: network.value,
          },
          // For torus only
          buttonDescription: "",
          walletVerifier: "",
        } as LoginConfigItem,
      } as LoginConfig,
    } as AppConfig;
  });

  const authVerifiers = computed(() => {
    if (config.value === null) return [];
    return Object.values(config.value.loginConfig).reduce((acc: string[], x) => {
      acc.push(x.verifier);
      if (x.walletVerifier) acc.push(x.walletVerifier);
      if (x.verifierSubIdentifier) acc.push(x.verifierSubIdentifier);
      return acc;
    }, []);
  });

  const isProdNetwork = computed(() => config.value.prodNetworks.includes(network.value));

  const isSapphireNetwork = computed(() => {
    return [TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET, TORUS_SAPPHIRE_NETWORK.SAPPHIRE_DEVNET].includes(network.value as TORUS_SAPPHIRE_NETWORK_TYPE);
  });

  return { config, authVerifiers, environment, network, setNetwork, metadataHost, setMetadataHost, isProdNetwork, isSapphireNetwork };
};

export default useConfig;
