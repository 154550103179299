/* eslint-disable no-restricted-syntax */
export function omit<T extends Record<string, unknown>>(obj: T, keys: string[]): T {
  if (typeof obj !== "object" || obj === null) return {} as T;

  const result = {} as Record<string, unknown>;

  for (const [key, value] of Object.entries(obj)) {
    if (!keys.includes(key)) {
      result[key] = value;
    }
  }

  return result as T;
}

export function pickBy<T>(object: Record<string, T>, predicate: (value: T, key: string) => boolean): Record<string, T> {
  // Create a new object to store the results
  const result: Record<string, T> = {};

  // Iterate over all own properties of the object
  for (const [key, value] of Object.entries(object)) {
    if (predicate(value, key)) {
      result[key] = value;
    }
  }

  return result;
}

export function cloneDeep<T>(object: T): T {
  try {
    return structuredClone(object);
  } catch (error) {
    return JSON.parse(JSON.stringify(object));
  }
}
