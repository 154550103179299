import { createStore } from "vuex";
// import createStore from "vuex";
import { config } from "vuex-module-decorators";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

const store = createStore({});

export default store;
